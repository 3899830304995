import { dlt, get, post, put } from './client'

const getMyTeams = async () => {
	return await get('teams/my-teams')
}

const getTeam = async ({ queryKey }) => {
	return await get(`teams/${queryKey[1]}`)
}
const getTeamByRfid = async ({ queryKey }) => {
	return await get(`teams/rfid/${queryKey[1]}`)
}

const getGroupTeams = async ({ queryKey }) => {
	return await get(`teams/all-teams/${queryKey[1]}`, { token: queryKey[2] })
}

const getPublicTeamCount = async ({ queryKey }) => {
	return await get(`teams/all-teams/${queryKey[1]}/count`, {
		token: queryKey[2]
	})
}

const getTeamMembers = async ({ queryKey }) => {
	return await get(`team-members/${queryKey[1]}`)
}

const createTeam = async (body) => {
	return await post('teams', { body })
}

const updateTeam = async ({ id, name, avatar }) => {
	return await put(`teams/${id}`, {
		body: {
			name,
			avatar
		}
	})
}

const joinTeam = async ({ id, teamId }) => {
	return await post('team-group', {
		body: {
			groupId: parseFloat(id),
			teamId: parseFloat(teamId)
		}
	})
}

const leaveTeam = async ({ id, teamId }) => {
	return await dlt('team-group', {
		body: {
			groupId: parseFloat(id),
			teamId: parseFloat(teamId)
		}
	})
}

const deleteTeam = async ({ teamId }) => {
	return await dlt(`teams/${teamId}`)
}

const myCurrentTeam = async ({ queryKey }) => {
	return await get(`teams/my-current-team/${queryKey[1]}`)
}

export {
	getMyTeams,
	getTeam,
	getTeamMembers,
	getGroupTeams,
	getPublicTeamCount,
	createTeam,
	updateTeam,
	leaveTeam,
	joinTeam,
	deleteTeam,
	getTeamByRfid,
	myCurrentTeam
}
